<template>
  <div style="width: 100%; height: 100%;cursor: none;" onselectstart="return false">
    <div
      v-for="(pic, index) in quiz_pics"
      :key="index"
      style="margin-bottom: 20px"
    >
      <a :href="formatPicUrl(pic.picture_address)" target="_blank">
        <img
          :src="formatPicUrl(pic.picture_address)"
          style="width: 100%; height: auto; object-fit: cover"
        />
      </a>
    </div>
    <p class="content-title">{{ session_name }}</p>
    <p class="content-intro" style="margin: 20px 0px">
      <b>{{ content_info.content_info.content_name }}</b>
    </p>
    <div
      class="content-intro"
      v-html="formatHtmlText(content_info.content_info.html_text)"
    ></div>
  </div>
</template>

<script>
import "../assets/common/font.css";

export default {
  name: "ExamContentView",
  props: {
    session_name: {
      type: String,
      default: "",
    },
    content_info: {
      type: Object,
      default: {},
    },
    quiz_pics: {
      type: Array,
      default: [],
    },
  },
  methods: {
    formatPicUrl(picture_address) {
      if (
        picture_address.startsWith("https") ||
        picture_address.startsWith("http")
      ) {
        return picture_address;
      }
      return "http://" + picture_address;
    },

    formatHtmlText(html_text) {
      if(html_text && html_text !=''){
        let result = html_text;
      try {
        let index = result.indexOf("<input");
        if (index != -1) {
          result = result.replace('<input type=\"text\"', '<input type=hidden')
          return this.formatHtmlImg(result);
        }
      } catch (e) {
        return this.formatHtmlImg(html_text);
      }
      return this.formatHtmlImg(html_text);
      }
      return ''
    },
    formatHtmlImg(content) {
      return content.replace(/<img/g,"<img style='max-width:100%;height:auto;'");
      // content = content.replace(/<img[^>]*>/gi, function (match, capture) {
      //   return match.replace(/(style="(.*?)")|(width="(.*?)")|(height="(.*?)")/ig,
      //       'style="max-width:100%;height:auto;"')
      // });
      // return content;
    },
  },
};
</script>

<style scoped lang="less">
.content-title {
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-75-SemiBold;
  color: #333333;
}

.content-intro {
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-55-Regular;
  color: #333333;
  line-height: 32px;
}
</style>
