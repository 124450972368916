import { render, staticRenderFns } from "./StudentExam.vue?vue&type=template&id=6305d478&scoped=true"
import script from "./StudentExam.vue?vue&type=script&lang=js"
export * from "./StudentExam.vue?vue&type=script&lang=js"
import style0 from "./StudentExam.vue?vue&type=style&index=0&id=6305d478&prod&lang=css"
import style1 from "./StudentExam.vue?vue&type=style&index=1&id=6305d478&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6305d478",
  null
  
)

export default component.exports